<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
          @change="handleGetPeriferico"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="perifericos"
        sort-by="ds_placa_cavalo"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Periféricos Cadastrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="() => handleGetPeriferico"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  @click="adicionarVeiculo"
                  >Novo Periférico</v-btn
                >
              </template>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="canUpdate"
            small
            class="mr-2"
            @click="$emit('editPeriferico', item)"
            >mdi-pencil</v-icon
          >
          <v-icon small v-if="canDelete" @click="prepareDeleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
    </div>

    <v-dialog v-if="canDelete" v-model="dialogDeleteItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Cuidado!</h3>
            <p class="mt-8">Tem certeza que deseja deletar este periférico?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="
              dialogDeleteItem = false
              perifericoDelete = {}
            "
          >
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="deleteItem(perifericoDelete)">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
export default {
  name: 'ListarPerifericos',
  data() {
    return {
      dialog: false,
      search: '',
      loading: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Endereço IP', value: 'ip' },
        { text: 'Ações', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
      dialogDeleteItem: false,
      perifericoDelete: {},
    }
  },
  computed: {
    ...mapState('patio', ['perifericos']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Veiculos.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Novo' : 'Editar'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.getPeriferico()
    }
  },

  methods: {
    ...mapActions('patio', [
      'getPeriferico',
      'editPeriferico',
      'deletePeriferico'
    ]),

    adicionarVeiculo() {
      this.$emit('showForm')
    },
    async handleGetPeriferico(search) {
      this.loading = true
      if (search.length >= 3) {
        await this.getPeriferico({ search })
      } else {
        alert('IP não encontrado')
      }
      this.loading = false
    },

    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.perifericoDelete = item
    },

    async deleteItem(item) {
      if (item.id) {
        await this.deletePeriferico(item)
      } else {
        alert('Houve um problema. Tente novamente!')
        return false
      }
      this.dialogDeleteItem = false
      this.perifericoDelete = {}
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
  },
}
</script>
