<template>
  <v-container>
    <v-card-title class="h1 justify-center"
      >{{ action }} Cadastrar Periférico</v-card-title
    >
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <!--<v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="proprietario"
              rules="required"
            >
              <v-text-field
                :error-messages="errors"
                label="Proprietário"
                prepend-icon="mdi-home-city"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>-->

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" name="ip" rules="required">
              <v-text-field
                v-model="novoPeriferico.ip"
                :error-messages="errors"
                label="Endereço IP"
                prepend-icon="mdi-domain"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-card-actions>
              <v-spacer />
              <buttons-cadastro
                :disabled="invalid"
                :value="action === 'Editar' ? 'Atualizar' : 'Cadastrar'"
                @save="save"
                @close="$emit('addedPeriferico')"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>


<script>
import { mapActions, mapState } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'


export default {
  components: { ButtonsCadastro },
  name: 'CadastroPeriferico',
  props: {
    perifericoEdit: {
      type: Object,
      required: false,
    },
  },

  data: function () {
    return {
      novoPeriferico: {},
      isEdit: false,
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  async created() {
    if (!this.empresaAtual.public_id) return

    if (Object.keys(this.perifericoEdit || {}).length) {
      this.novoPeriferico = { ...this.perifericoEdit }

      this.atualizaDados()
    }
  },

  methods: {
    ...mapActions('patio', ['addPeriferico', 'editPeriferico']),

    ...mapActions('template', ['errorMessage', 'successMessage']),

    atualizaDados() {
      Object.keys(this.novoPeriferico).forEach((key) => {
        if (
          typeof this.novoPeriferico[key] === 'object' &&
          this.novoPeriferico[key] !== null
        ) {
          if (this.novoPeriferico[key].public_id)
            this.novoPeriferico[key] = this.novoPeriferico[key].public_id
          else if (this.novoPeriferico[key].id)
            this.novoPeriferico[key] = this.novoPeriferico[key].id
        }
      })
      this.isEdit = Object.keys(this.novoPeriferico).length
    },

    async save() {
      //if (this.validate()) {}
      try {
        let periferico
        if (this.isEdit) {
          periferico = await this.editPeriferico({
            ...this.novoPeriferico,
            proprietario: this.empresaAtual.public_id,
          })
          this.$emit('addedPeriferico', periferico)
          this.successMessage('Periférico atualizado com sucesso!')
        } else {
          periferico = await this.addPeriferico({
            ...this.novoPeriferico,
            proprietario: this.empresaAtual.public_id,
          })
          this.$emit('addedPeriferico', periferico)
          this.successMessage('Periférico cadastrado com sucesso!')
          this.novoPeriferico = {}
        }
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
  },
}
</script>
<style></style>
