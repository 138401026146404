<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Periféricos">
          <cadastro-periferico v-if="showForm" :perifericoEdit="perifericoEdit" @addedPeriferico="showForm = false; perifericoEdit = {}" />
          <listar-perifericos v-else @showForm="showForm = true" @editPeriferico="editPeriferico" />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarPerifericos from '@/components/patio/Perifericos/ListarPerifericos.vue'
import CadastroPeriferico from '@/components/patio/Perifericos/CadastroPeriferico.vue'

export default {
  data() {
    return {
      showForm: false,
      perifericoEdit: {},
      page: {
        title: 'Gerenciamento de Periféricos',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Periféricos',
          disabled: false,
        },
      ],
    }
  },
  components: {
    CadastroPeriferico,  
    ListarPerifericos
  },
  methods: {
    editPeriferico(periferico) {
      this.perifericoEdit = periferico
      this.showForm = true
    }
  }
}
</script>